var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background" }, [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("QaForm", {
          attrs: {
            form: _vm.form,
            info: _vm.info,
            "show-form": _vm.showForm,
            sections: _vm.sections,
            "submit-btn-name": _vm.submitBtnName,
            loading: _vm.loading,
          },
          on: {
            backToQaIndex: _vm.backToQaIndex,
            postFormSubmit: _vm.postFormSubmit,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header-title" }, [_vm._v("Q&A 詳細")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }