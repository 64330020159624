var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c("h3", { staticClass: "header-title" }, [_vm._v("求人一覧")]),
        _vm._v(" "),
        _c(
          "el-row",
          { attrs: { justify: "space-between", type: "flex" } },
          [
            _c(
              "el-button",
              {
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goCreatePage()
                  },
                },
              },
              [_vm._v("新規作成")]
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { staticStyle: { "flex-grow": "1" } },
              [_c("JobsFilter")],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { margin: "32px" } },
          [
            _c("label", [
              _vm._v(
                _vm._s(_vm.currentPage + 1) + " / " + _vm._s(_vm.maxPage + 1)
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goFirstPage()
                  },
                },
              },
              [_vm._v("先頭ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goPrePage()
                  },
                },
              },
              [_vm._v("前ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goNextPage()
                  },
                },
              },
              [_vm._v("次ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goLastPage()
                  },
                },
              },
              [_vm._v("最終ページ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.jobs, stripe: "", fit: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { align: "center", width: "90" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm.shouldShowPhoto(row)
                            ? _c("img", {
                                staticStyle: {
                                  width: "100%",
                                  "object-fit": "cover",
                                },
                                attrs: {
                                  src: _vm.getPhotoUrl(row),
                                  alt: "Job Photo",
                                },
                              })
                            : _c("img", {
                                staticStyle: {
                                  width: "100%",
                                  "object-fit": "cover",
                                },
                                attrs: {
                                  src: require("@/assets/img/no_image.png"),
                                },
                              }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "id",
                    label: "求人番号",
                    align: "center",
                    "min-width": "56",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "job_term",
                    label: "勤務期間",
                    align: "center",
                    width: "90",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "募集会社", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("truncate")(row.company_name, 60, "...")
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "募集内容",
                    align: "center",
                    "min-width": "100",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("truncate")(
                                  row.recruit_description,
                                  60,
                                  "..."
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "給与", align: "center", "min-width": "35" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.salary_type && scope.row.salary
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.salary_type.text) +
                                    " " +
                                    _vm._s(scope.row.salary) +
                                    "\n            "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.salary) +
                                    "\n            "
                                ),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "勤務地",
                    align: "center",
                    "min-width": "120",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("truncate")(row.full_address, 60, "...")
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "応募締め切り",
                    align: "center",
                    "min-width": "50",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.displayDate(row.application_period)) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "recruit_status",
                    label: "募集ステータス",
                    align: "center",
                    "min-width": "50",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "buttons",
                  attrs: { align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "button-container" },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "info", plain: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewDetails(scope.row.id)
                                        },
                                      },
                                    },
                                    [_vm._v("詳細")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "info", plain: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewApplicants(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("応募者")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "info", plain: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyJob(scope.row.id)
                                        },
                                      },
                                    },
                                    [_vm._v("コピー")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { margin: "32px" } },
          [
            _c("label", [
              _vm._v(
                _vm._s(_vm.currentPage + 1) + " / " + _vm._s(_vm.maxPage + 1)
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goFirstPage()
                  },
                },
              },
              [_vm._v("先頭ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goPrePage()
                  },
                },
              },
              [_vm._v("前ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goNextPage()
                  },
                },
              },
              [_vm._v("次ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goLastPage()
                  },
                },
              },
              [_vm._v("最終ページ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }